import React from "react"
import { ZigbangErrorScreen } from "@zigbang/screens/home/ZigbangErrorScreen"

export default class Page extends React.Component {
	static navigationOptions: any

	render() {
		return (
			<ZigbangErrorScreen
				{...this.props}
				title="원하시는 페이지를 찾을 수가 없습니다."
				description="방문하시려는 페이지의 주소가 잘못 입력되었거나, 페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다."
				enableHeader
			/>
		)
	}
}
